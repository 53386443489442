import { useEffect, useState } from "react";
import { StationRating, StationReviewCard } from "../../components/reviews/StationReview";
import { Link, useParams } from "react-router-dom";
import { getSingleStation } from "../../components/stations/StationsAPI";
import { getstationReviews } from "../../components/reviews/ReviewAPI";
import { Box, Button, Card, CardContent, CircularProgress, Grid, Paper, Skeleton, Typography, styled } from "@mui/material";

import RatingInput from "../../components/resources/RatingInput";
import CapitalizeFirstLetter from "../../components/resources/CapitalizeFirstLetter";
// import Mapc from "../../components/resources/Map";
import CustomPagination from "../../components/resources/CustomPagination";
import { getUser } from "../../components/user/UserApi";
import { useSelector } from 'react-redux';

const StationPage = () => {

    const [reviews, setReviews] = useState([]);
    const [station, setStation] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [user, setUser] = useState([]);
    const itemsPerPage = 10; // Number of items to display per page
    const authenticatedUser = useSelector((state) => state.userAuth);

    const { station_id } = useParams();
    
    useEffect(() => {
        const fetchSingleStation = async () => {
            try {
                const data = await getSingleStation(station_id);
                setStation(data);
                const userData = await getUser();
                setUser(userData);
            } catch (error) {
                console.error('Error fetching station:', error);
            }
        };
        fetchSingleStation();
    }, [station_id]);

    useEffect(() => {
        if (station && station.station_id) {
            const fetchStationReview = async () => {
                try {
                    const data = await getstationReviews(station.station_id);
                    setReviews(data.reviews);
                } catch (error) {
                    console.error('Error fetching station reviews:', error);
                }
            };
            fetchStationReview();
        }
    }, [station]);

    const StyledSection = styled(Paper)(({ theme }) => ({
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    }));
    
    const StyledRatingContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    }));
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = reviews.slice(startIndex, endIndex);
    
    return (
        <div style={{ background: '#eef3f7'}}>
            {
                station ? (
                    <div>
                        <Paper>
                        <StyledSection>
                            {station && (
                                <Grid container spacing={2}>
                                    <Grid  item xs={2}>
                                        <figure>
                                            <img alt={station.name} src=""/>
                                        </figure>
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={5}>
                                        <Typography variant="h2"><strong>{CapitalizeFirstLetter(station.name)}</strong></Typography>
                                        <Typography variant='body1'>Reviews {reviews.length}</Typography>
                                        <StyledRatingContainer>
                                            <RatingInput value={station.rating} size="large" readOnly />
                                            <Typography variant='body1' className="mt-3">{Number(station.rating).toFixed(2)}/ 5.00</Typography>
                                        </StyledRatingContainer>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={12} lg={4}>
                                        <StationRating reviews={reviews} star={station && (station.rating)}/>
                                    </Grid>
                                </Grid>
                            )}
                        </StyledSection>
                        </Paper>
                        <div className="mx-3">
                            {station && 
                                <Button href={`/reviews/add/${station.station_id}`} variant="contained" color="primary">Add Review</Button>
                            }
                        </div>
                        <Grid container spacing={2} className="container">
                            <Grid item xs={12} md={7}>
                                {
                                    currentData.length === 0 ? (
                                        Array.from({ length: 12 }).map((_, index) => (
                                            <Card className="mt-4" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
                                                <CardContent>
                                                    <Skeleton variant="text" width={120} height={30} />

                                                    <div className="flex items-center mb-3 space-x-4">
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <div className="space-y-1 font-medium">
                                                        <Skeleton variant="text" width={150} height={20} />
                                                        <Skeleton variant="text" width={120} height={15} />
                                                    </div>
                                                    </div>

                                                    <div className="flex items-center mb-1">
                                                    {[1, 2, 3, 4].map((index) => (
                                                        <Skeleton
                                                        key={index}
                                                        variant="rectangular"
                                                        width={20}
                                                        height={20}
                                                        style={{ marginRight: '4px' }}
                                                        />
                                                    ))}
                                                    <Skeleton variant="text" width={180} height={20} />
                                                    </div>

                                                    <footer className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                                    <Skeleton variant="text" width={120} height={15} />
                                                    </footer>

                                                    <Skeleton variant="text" width={'100%'} height={40} />

                                                    <aside>
                                                    <Skeleton variant="text" width={150} height={15} />

                                                    <div className="flex items-center mt-3 space-x-3 divide-x divide-gray-200 dark:divide-gray-600">
                                                        <Button
                                                        className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-xs px-2 py-1.5 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                        disabled
                                                        >
                                                        Helpful
                                                        </Button>

                                                        <Link
                                                        href="#"
                                                        className="pl-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                                                        >
                                                        Report abuse
                                                        </Link>
                                                    </div>
                                                    </aside>
                                                </CardContent>
                                            </Card>
                                        ))
                                    ) : (
                                        currentData.map((review) => (
                                            <StationReviewCard reviews={review} users={user && user} userId={authenticatedUser?.user?._id} />
                                        ))
                                    )
                                }
                                
                                <CustomPagination
                                    totalItems={reviews.length}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                            <Card className="mt-4 sticky-list-card" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
                                <CardContent>
                                    <ul>
                                        <li className="mb-3">Average Price: <span style={{float: "right", fontWeight: "bold", fontSize: "25px"}}>{Number(station?.average_price).toFixed(2)}</span></li>
                                        <li>Address: <span style={{float: "right", fontWeight: "bold"}}>{station.address}</span></li>
                                        <li>State: <span style={{float: "right", fontWeight: "bold"}}>{station.state}</span></li>
                                        <li>Lga: <span style={{float: "right", fontWeight: "bold"}}>{station.lga}</span></li>
                                    </ul>
                                </CardContent>
                            </Card>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={80} />
                    </div>
                )
            }
        </div>
    )
};

export default StationPage;