import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { registerUserAction } from '../../Slice/UserSlice';
import { Alert, FormHelperText } from '@mui/material';
import { toast } from 'react-toastify';

const Register = () => {
    const [user, setUser] = useState({
        email: "",
        username: "",
        password: "",
    });
    const [formErrors, setFormErrors] = useState({
        email: "",
        username: "",
        password: "",
    });

    const storeData = useSelector((store) => store?.users);
    const { loading, registrationError, isAuthenticated, registrationSuccess } = storeData;
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/"); // Redirect to home page or another appropriate page
        }
    })
    // Validate email format using a regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    
    const validateForm = () => {
        let valid = true;
        const newErrors = {
            email: "",
            username: "",
            password: "",
        };
      
        if (!emailPattern.test(user.email)) {
            newErrors.email = 'Incorrect email format';
            valid = false;
        }
    
        // Validate Price
        if (user.username.trim() === '') {
          newErrors.username = 'Username is required';
          valid = false;
        }else if(user.username.trim().length < 3){
            newErrors.username = 'Username is too Short';
            valid = false;
        }
      
        // Validate Comment
        if (user.password.trim() === '') {
          newErrors.password = 'password is required';
          valid = false;
        }
      
        setFormErrors(newErrors);
        return valid;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                dispatch(registerUserAction(user));
                if(registrationSuccess === 'User registered') {
                    toast.success('Registration Successful.', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    window.open(`/login`, '_self');
                    window.history.replaceState({}, document.title, '/');
                };
            } catch (error) {
                console.error('Registration Error', error)
            }
        };
    };

    const cardStyle = {
        border: '0',
        borderRadius: '27.5px',
        boxShadow: '0 10px 30px 0 rgba(172, 168, 168, 0.43)',
        overflow: 'hidden',
    };
    
    const imgStyle = {
        borderRadius: '0',
        position: 'absolute',
        width: '45%',
        height: '100%',
        // objectFit: 'cover',
    };
    
    const cardBodyStyle = {
        padding: '85px 60px 60px',
    };
    
    const footerTextStyle = {
        fontSize: '16px',
        color: '#0d2366',
        marginBottom: '60px',
    };
    
    const footerNavLinkStyle = {
        fontSize: '14px',
        color: '#919aa3',
    };

    const loginInput = {
        border: '1px solid #d5dae2',
        padding: '15px 25px',
        minHeight: '45px',
        fontSize: '13px',
        lineHeight: '1.2',
        fontWeight: 'normal',
    };

    return (
        <div>
            <div style={{ background: '#eef3f7'}}>
                <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
                    <div className="container">
                        <div className="card login-card" style={cardStyle}>
                        <div className="row no-gutters">
                            <div className="col-md-5 form-img">
                            <img
                                src="https://th.bing.com/th/id/R.8a681235f470a3471b6391dccd1b7359?rik=rNJLshJd1JfAFQ&pid=ImgRaw&r=0"
                                alt="login"
                                className="login-card-img"
                                style={imgStyle}
                            />
                            </div>
                            <div className="col-md-7">
                            <div className="card-body" style={cardBodyStyle}>
                                {registrationError ? (
                                    <Alert variant="filled" severity="error">
                                        {registrationError}
                                    </Alert>
                                ) : ''
                                }
                                <div className="brand-wrapper">
                                <img
                                    src="assets/images/logo.svg"
                                    alt="logo"
                                    className="logo"
                                    style={{ height: '37px' }}
                                />
                                </div>
                                <p className="login-card-description" style={{ fontSize: '25px', color: '#000', fontWeight: 'normal', marginBottom: '23px' }}>Sign into your account</p>
                                <form action="#!">
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="sr-only">
                                    Email
                                    </label>
                                    <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="Email address"
                                    value={user.email}
                                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                                    style={loginInput}
                                    />
                                    <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.email}</FormHelperText>
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="sr-only">
                                    Email
                                    </label>
                                    <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    className="form-control"
                                    placeholder="username"
                                    value={user.username}
                                    onChange={(e) => setUser({ ...user, username: e.target.value })}
                                    style={loginInput}
                                    />
                                    <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.username}</FormHelperText>
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="password" className="sr-only">
                                    Password
                                    </label>
                                    <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="***********"
                                    value={user.password}
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    style={loginInput}
                                    />
                                    <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.password}</FormHelperText>
                                </div>

                                <input
                                    name="register"
                                    id="register"
                                    className="btn btn-block login-btn mb-4"
                                    type="button"
                                    value={ loading ? "Registering..."  : "Register"} onClick={handleSubmit} disabled={loading}
                                />
                                </form>

                                <p className="login-card-footer-text" style={footerTextStyle}>
                                Already have an account?{' '}
                                <Link to="/login" className="text-reset">
                                    Login here
                                </Link>
                                </p>
                                <nav className="login-card-footer-nav">
                                <Link to="#!" style={footerNavLinkStyle}>
                                    Terms of use.
                                </Link>
                                <Link to="#!" style={footerNavLinkStyle}>
                                    Privacy policy
                                </Link>
                                </nav>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Register;
