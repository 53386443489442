// userAuthSlice.js
import { createSlice } from '@reduxjs/toolkit';

// userAuthSlice.js (continued)
export const fetchUserDetails = () => async (dispatch, getState) => {
    const { token } = getState().userAuth;
  
    if (token) {
      try {
        // Make an API request to fetch user details using the token
        const response = await fetch('/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const userData = await response.json();
          dispatch(setUser(userData)); // Update user details in the store
        }
      } catch (error) {
        // Handle errors, e.g., token expired or network issues
      }
    }
};
  
const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState: {
    token: localStorage.getItem('token') || null, // Get the token from local storage if available
    user: null, // User details
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const { setToken, setUser, clearUser } = userAuthSlice.actions;
export default userAuthSlice.reducer;
