import React, { useEffect } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

// Pages & components
import Home from './pages/Home';
import Navbar1 from './components/resources/Navbar';
import Footer from './components/resources/Footer';

import { Routes, Route, useLocation } from 'react-router-dom';
import ReviewsList from './pages/reviews/ReviewsList';
import AddReview from './pages/reviews/AddReview';
import Stations from './pages/station/Stations';
import EditReview from './pages/reviews/EditReview';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import { AdminRoute, ProtectedRoute } from './middleware/ProtectedRoute';
import { useSelector } from 'react-redux';
import AddStation from './pages/station/AddStation';
import AllUsers from './pages/Admin/user/AllUsers';
import AllStations from './pages/Admin/station/AllStations';
import AllReviews from './pages/Admin/reviews/AllReviews';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import StationPage from './pages/station/Station-Page';
import UserDashBoard from './pages/user/Dashboard';
import UnapprovedStations from './pages/Admin/station/UnapprovedStations';
import EditStation from './pages/station/EditStation';
import UserAccount from './pages/user/UserAccount';
import ReportedReviews from './pages/Admin/reviews/ReportedReviews';
import ReportReview from './pages/reviews/ReportReview';
import useAuth from './middleware/AuthUser';
import NotFound from './components/resources/404Page';

function App() {
  const isAuthenticated = useSelector((state) => state.users);
  const authenticatedUser = useSelector((state) => state.userAuth);
  const { fetchUserDetails } = useAuth();

  // Simulated user token (replace with your actual token retrieval logic)
  const userToken = localStorage.getItem('token');

  useEffect(() => {
    // Fetch user details when the component mounts or when the token changes
    fetchUserDetails(userToken);
  }, [fetchUserDetails, userToken]);
  
  const location = useLocation();
  const hideNavbarAndFooterRoutes = [
    '/login',
    '/sign_up',
    '/all/users',
    '/reported reviews',
    '/all/stations',
    '/all/reviews',
    '/stations/unapproved',
  ]; // Add routes where you want to hide the Navbar and Footer

  const shouldHideNavbarAndFooter = hideNavbarAndFooterRoutes.includes(location.pathname);

  return (
    <div className="App">
      <ToastContainer />
        {!shouldHideNavbarAndFooter && <Navbar1 />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reviews" element={<ReviewsList />} />
          <Route path='/stations' element={<Stations />} />
          <Route path='/user/:username' element={<UserAccount />} />
          <Route path='/station/:station_id' element={<StationPage />} />

          {/* Form */}
          <Route path='/sign_up' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/edit_reviews/:station_id/:reviewId' element={
            <ProtectedRoute>
              <EditReview />
            </ProtectedRoute>
          } /> 
          <Route path='/stations/add' element={<AddStation />} />
          <Route path="/reviews/add/:station_id" element={<AddReview />} />
          <Route path='/edit_stations/:station_id' element={<EditStation />} />
          <Route path='/report/:review_Id' element={<ReportReview />} />


          {/* Admin */}
          <Route path='/all/users' element={
            <AdminRoute>
              <AllUsers />
            </AdminRoute>
          } />
          <Route path="/reported reviews" element={
            <AdminRoute>
              <ReportedReviews />
            </AdminRoute>
          } />
          <Route path='/all/stations' element={<AllStations />} />
          <Route path='/all/reviews' element={<AllReviews />} />
          <Route path='/stations/unapproved' element={<UnapprovedStations />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        {!shouldHideNavbarAndFooter && <Footer />}
    </div>
  );
}

export default App;
