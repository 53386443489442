import React, { useEffect, useState } from "react";
import { editStation, getStations } from "../../../components/stations/StationsAPI";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PaginationTable from "../../../components/resources/PaginationTable";
import { ErrorModal } from "../../../components/resources/Modals";

const UnapprovedStations = () => {
    
    const [stations, setStations] = useState([]);
    const [filteredStations, setFilteredStations] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [stationToApprove, setStationToApprove] = useState(null);
    
    useEffect(() => {
        fetchStations();
    }, []);

    const fetchStations = async () => {
        try {
            const data = await getStations();
            setStations(data.station);
        } catch (error) {
            console.error('Error fetching Stations:', error);
        }
    };

    useEffect(() => {
        // Filter stations with isApproved === false
        const filtered = stations.filter(item => !item.isApproved);
        setFilteredStations(filtered);
    }, [stations]);

    const showApproveModal = (stationId) => {
        setStationToApprove(stationId);
        setIsModalVisible(true);
    };

    const approveStation = async () => {
        try {
            await editStation({ isApproved: true, station_id: stationToApprove });
            // Remove the approved station from the filtered list
            setFilteredStations(filteredStations.filter(station => station._id !== stationToApprove));
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error Updating Station', error);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        { id: 'id', label: 'ID', minWidth: 100 },
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'Station_id', label: 'Station ID', minWidth: 100 },
        { id: 'State', label: 'State', minWidth: 170, align: 'right' },
        { id: 'LGA', label: 'LGA', minWidth: 170, align: 'right' },
        {
            id: 'tableAction',
            label: 'Action',
            minWidth: 170,
            align: 'right',
        },
    ];

    const createData = (id, name, Station_id, State, LGA) => {
        return { id, name, Station_id, State, LGA };
    }

    // Add the "Add Station" button as a row
    const rows = filteredStations.map((station) => ({
        id: station._id,
        name: station.name,
        Station_id: station.station_id,
        State: station.state,
        LGA: station.lga,
        tableAction: (
            <>
                <Button href={`edit_stations/${station._id}`}>Edit</Button>
                <Button onClick={(e) => {
                    e.preventDefault();
                    showApproveModal(station._id);
                }}>Approve</Button>
            </>
        ),
    }));

    const errorContent = (
        <>
          <p>Do you want to approve this station?</p>
        </>
    );
    
    return (
        <AdminLayout>
            <ErrorModal
                errorContent={errorContent}
                isVisible={isModalVisible}
                onYes={approveStation}
                title={'Confirm Approval'}
                onNo={handleCancel}
            />
            <div style={{ justifyContent: 'space-between', display: 'flex'}}>
                <h2>Unapproved Stations</h2>
                {/* Button is now part of the table */}
            </div>
            <PaginationTable columns={columns} rows={rows}/>
        </AdminLayout>
    )
}

export default UnapprovedStations;
