import React, { useEffect, useState } from "react";
import { Grid, styled, Paper, CardContent, Button, Card, Skeleton } from "@mui/material";
import { getStations } from "../../components/stations/StationsAPI";
import { getStates } from "../../components/states/StateApi";
import StationList from "../../components/stations/StationList";
import FilterSection from "../../components/stations/FilterSection";
import CustomPagination from "../../components/resources/CustomPagination";
import { AddCircleOutline } from "@mui/icons-material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexGrow: 1,
}));

const Stations = () => {
  const [stations, setStations] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState('all');
  const [selectedRating, setSelectedRating] = useState('All');
  const [selectedLGA, setSelectedLGA] = useState('all');
  const [filteredStations, setFilteredStations] = useState([]);
  const [averagePrice, setAveragePrice] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  useEffect(() => {
    fetchStationsData();
    fetchStatesData();
  }, []);
  
  const fetchStationsData = async () => {
    try {
      const data = await getStations();
      setStations(data.station);
      setFilteredStations(data.station);
    } catch (error) {
      console.error('Error fetching Stations:', error);
    }
  };

  const fetchStatesData = async () => {
    try {
      const data = await getStates();
      setStateData(data);
    } catch (error) {
      console.error('Error fetching States:', error);
    }
  };

  useEffect(() => {
    // Apply filters based on selectedRating, selectedState, and selectedLGA
    let filtered = stations;

    if (selectedRating !== 'All') {
      filtered = filtered.filter(item => item.rating >= Number(selectedRating));
    }

    if (selectedState !== 'all') {
      filtered = filtered.filter(item => item.state.toLowerCase() === selectedState.toLowerCase());
    }

    if (selectedLGA !== 'all') {
      filtered = filtered.filter(item => item.lga.toLowerCase() === selectedLGA.toLowerCase());
    }

    if (averagePrice !== '') {
      filtered = filtered.filter(item => item.average_price >= averagePrice);
    }

    // Filter approved stations (isApproved === true)
    filtered = filtered.filter(item => item.isApproved === true);

    setFilteredStations(filtered);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [selectedRating, selectedState, selectedLGA, stations, averagePrice]);

  const handleOptionChange = (value) => {
    setSelectedRating(value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedLGA('all');
  };

  const handleLGAChange = (event) => {
    setSelectedLGA(event.target.value);
  };

  const handlePriceChange = (event) => {
    setAveragePrice(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredStations.slice(startIndex, endIndex);

  const currentLGA = stateData.find((state) => state.name === selectedState);

  return (
    <div style={{ padding: '40px', background: '#eef3f7'}}>
      <div className="d-flex" style={{ justifyContent: 'space-between' }}>
        <h1>Find Nearby Filling Stations.</h1>
        <div style={{ marginBottom: '20px' }}>
          <Button href="/stations/add" variant="outlined"  className="btn-primary">
            <AddCircleOutline className="mr-2" /> Add New Station
          </Button>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <StyledPaper elevation={3}>
            <FilterSection
              selectedRating={selectedRating}
              handleOptionChange={handleOptionChange}
              selectedState={selectedState}
              stateData={stateData}
              handleStateChange={handleStateChange}
              selectedLGA={selectedLGA}
              currentLGA={currentLGA}
              handleLGAChange={handleLGAChange}
              averagePrice={averagePrice}
              handlePriceChange={handlePriceChange}
            />
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
          {
            currentData.length === 0 ? (
              Array.from({ length: 5 }).map((_, index) => (
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <Card className="mt-4" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
                    <CardContent>
                      <div className="Listing" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                        <div className="Image" style={{ width: '200px', height: '200px', borderRadius: '12px', overflow: 'hidden', border: '0.50px #E5E7EB solid', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <Skeleton variant="rectangular" width={200} height={200} />
                        </div>
                        <div className="Content" style={{ flex: '1', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '16px' }}>
                          <div className="Header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '24px' }}>
                            <div className="TitleSubtitle" style={{ flex: '1', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '8px' }}>
                              <Skeleton variant="text" width={200} height={32} />
                              <Skeleton variant="text" width={120} height={20} />
                            </div>
                            <div className="Heart" style={{ width: '32px', height: '32px', position: 'relative' }}>
                              <div className="Vector" style={{ width: '19.33px', height: '16.67px', left: '6.33px', top: '7.67px', position: 'absolute', border: '0.75px #374151 solid' }}></div>
                            </div>
                          </div>
                          <div className="Divider" style={{ width: '40px', height: '0px', border: '0.50px #E5E7EB solid', marginTop: '16px', marginBottom: '14px' }}></div>
                          <div className="Details" style={{ height: '60px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Skeleton variant="text" width={'100%'} height={20} />
                          </div>
                          <div className="Divider" style={{ width: '40px', height: '0px', border: '0.50px #E5E7EB solid', marginTop: '16px', marginBottom: '14px' }}></div>
                          <div className="Footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
                            <div className="Review" style={{ height: '20px', justifyContent: 'flex-start', alignItems: 'center', gap: '4px', display: 'flex' }}>
                              <Skeleton variant="text" width={30} height={20} />
                              <div className="Star" style={{ height: '20px' }}>
                                <Skeleton variant="text" width={30} height={12} />
                              </div>
                            </div>
                            <div className="Review" style={{ justifyContent: 'flex-end', alignItems: 'center', gap: '8px', display: 'flex' }}>
                              <Skeleton variant="text" width={60} height={28} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              currentData.map((station) => (
                <Grid item key={station.id} xs={12} sm={6} md={12} lg={12}>
                  <StationList item={station} />
                </Grid>
              ))
            )
          }
          </Grid>

          <CustomPagination
            totalItems={filteredStations.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Stations;
