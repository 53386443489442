import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUserAction } from '../../Slice/UserSlice';
import { Button } from "@mui/material";

const Navbar1 = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action when the user clicks the logout button
    dispatch(logoutUserAction());

    // Reload the page
    window.location.reload();
  };

  const authenticatedUser = useSelector((state) => state.userAuth);

  const navbarStyle = {
    marginTop: '105px', // Adjust this value to match your navbar's height
  };

  return (
      <div style={navbarStyle}>
        <>
          <Navbar collapseOnSelect expand="lg" className="bg-white shadow fixed-top" style={{ zIndex: 1000 }}>
            <Container className='container mx-auto px-4 py-4'>
              <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Link className="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4" to="/">Home</Link>
                  <Link className="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4" to="/stations">Stations</Link>
                </Nav>
                <Nav className='me-auto'>
                  <div class="form">
                    <i class="fa fa-search"></i>
                    <input type="text" class="form-control form-input" placeholder="Search for Stations..." />
                    <span class="left-pan"><i class="fa fa-microphone"></i></span>
                  </div>
                </Nav>
                <Nav>
                {
                  authenticatedUser.user? (
                    <div>
                      <Button onClick={handleLogout} class="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4">Logout</Button>
                    </div>
                  ) : (
                    <div>
                      <Link to="/login" class="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4">Sign in</Link>
                      <Link to="#" class="text-gray-800 text-sm font-semibold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600">Sign up</Link>
                    </div>
                  )
                }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      </div>
  );
};

export default Navbar1;