import { Flag, ThumbUpAlt, ThumbUpOutlined } from "@mui/icons-material";
import { Avatar, Button, Card, CardActions, CardContent, Grid, Skeleton } from "@mui/material";
import { Rate, Typography } from "antd";
import moment from 'moment';
import CapitalizeFirstLetter from "../resources/CapitalizeFirstLetter";
import { Link } from "react-router-dom";
import { likeReview } from "./ReviewAPI";
import { useEffect, useState } from "react";

const formatTimeAgo = (timestamp) => {
    const now = moment();
    const reviewTime = moment(timestamp);
    const diffMinutes = now.diff(reviewTime, 'minutes');

    if (diffMinutes < 1) {
        return 'just now';
    } else if (diffMinutes < 60) {
        return `${diffMinutes} minutes ago`;
    } else if (diffMinutes < 1440) {
        return `${Math.floor(diffMinutes / 60)} hours ago`;
    } else if (diffMinutes <= 10080) {
        return `${Math.floor(diffMinutes / 1440)} days ago`;
    } else {
        return` on ${reviewTime.format('YYYY-MM-DD')}`;
    }
};

const formatTimestamp = (timestamp) => {
    const formattedTime = moment(timestamp).format('MMMM YYYY');
    return formattedTime;
};

export const ReviewCard = ({ reviews, userId, users}) => {
    const [liked, setLiked] = useState(false);
    
    useEffect(() => {
        if (reviews.likes.includes(`${userId}`)) {
          setLiked(true);
        }
      }, [reviews.likes, userId]);

      const handleLike = async () => {
        try {
          // Call your likeReview function with the review ID
          setLiked(!liked); // Toggle the liked state
          await likeReview(reviews._id);
        } catch (error) {
          console.error('Error liking review', error);
        }
      };
    return(
        <Card className="mt-4" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
            <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', color: '#6B7280' }}>
                    <Avatar alt="John Lucas" src={users?.find(user => user.username === reviews.username)?.profilePicture || "https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1036&q=80"} />
                    {/* <Avatar alt="John Lucas" src="https://images.unsplash.com/photo-1593104547489-5cfb3839a3b5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1036&q=80" />               */}
                <Typography className="ml-5" style={{ fontSize: '1rem', color: '#374151', fontWeight: 'bold', cursor: 'pointer'}}>
                    <Link to={ reviews.username === 'Anonymous' ? '#' :`/user/${reviews.username}`} style={{ textDecoration: 'none', color: '#374151' }}>{CapitalizeFirstLetter(reviews.username)}</Link> reviewed <Link to={`/station/${reviews.station_id}`}>{reviews.station_name}</Link>
                </Typography>
                </div>
                <div className="d-flex flex-wrap">
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                        <Rate allowHalf name="rating" value={reviews.rating} precision={0.5} readOnly />
                    </div>
                    <span className="text-secondary ml-2 mt-1">
                        <em style={{ fontSize: '12px' }}>{reviews.rating}/5.00</em>
                    </span>
                    <span className="text-secondary mt-1" style={{ fontSize: '0.875rem', fontWeight: 'normal', justifyContent: 'flex-end', marginLeft: 'auto' }}>
                        {formatTimeAgo(reviews.created_at)}
                    </span>
                </div>
                <div style={{ fontSize: '1.25rem', color: '#374151', fontWeight: 'bold', cursor: 'pointer', transition: 'color 0.3s', ':hover': { textDecoration: 'underline' } }}>Product Review</div>
                <Typography style={{ fontSize: '1rem', color: '#6B7280', marginTop: '16px' }}>
                    {reviews.comment}
                </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: 'space-between' }}>
                <div>
                    <Button size="small" color="primary" onClick={handleLike} startIcon={!liked ? <ThumbUpOutlined />  : <ThumbUpAlt />}>
                    Like
                    </Button>
                </div>
                <Button size="small" href={`/report/${reviews._id}`} color="secondary" startIcon={<Flag />}>
                Report
                </Button>
            </CardActions>
        </Card>
    )
}

export const StationReviewCard = ({ reviews, userId, users}) => {
    const [liked, setLiked] = useState(false);
    const [review, setReview] = useState(reviews);

    useEffect(() => {
        if (reviews.likes.includes(`${userId}`)) {
          setLiked(true);
        }
    }, [reviews.likes, userId]);

    
    const handleLike = async () => {
        try {
            // Toggle the liked state
            setLiked(!liked);

            // Call your likeReview function with the review ID
            await likeReview(reviews._id);
            // Update the likes count in the component's state
            setReview((prevReviews) => ({
                ...prevReviews,
                likes: liked ? prevReviews.likes.filter(id => id !== userId) : [...prevReviews.likes, userId]
            }));
        } catch (error) {
            console.error('Error liking review', error);
        }
    };
    const rating = review.rating
    return(
        <>
        <Card className="mt-4" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
            <CardContent>
                <div class="flex items-center mb-3 space-x-4">
                    <img class="w-10 h-10 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt="" />
                    <div class="space-y-1 font-medium">
                        <p><Link to={ review.username === 'Anonymous' ? '/hh' :`/user/${review.username}`} style={{ textDecoration: 'none', color: '#374151' }}>{CapitalizeFirstLetter(review.username)}</Link> <time datetime="2014-08-16 19:00" class="block text-sm text-gray-500 dark:text-gray-400">Joined {formatTimestamp(users?.find((user) => user.username === review.username)?.createdAt) || 'N/A'}</time></p></div>
                </div>
                <div class="flex items-center mb-1">
                {Array.from({ length: 5 }, (_, index) => (
                    <span key={index} className={`w-4 h-4 mr-1 ${index < rating ? 'text-yellow-300' : 'text-gray-300 dark:text-gray-500'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                    </span>
                ))}
                    <h3 class="ml-2 mt-3 text-sm font-semibold text-gray-900">Thinking to buy another one!</h3>
                </div>
                <footer class="mb-4 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed {formatTimeAgo(review.created_at)}</p></footer>
                <p style={{ fontSize: '1rem', color: '#6B7280' }} class="mb-4 text-gray-500 dark:text-gray-400">{review.comment}</p>
                {/* <p class="mb-3">It is obviously not the same build quality as those very expensive watches. But that is like comparing a Citroën to a Ferrari. This watch was well under £100! An absolute bargain.</p> */}
                {/* <a href="#" class="block mb-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">Read more</a> */}
                <aside>
                    {
                        review.likes.length > 0 ? (
                            <p class="mt-1 text-xs">{liked? 'You and ' : ''} {review.likes.length + 'other people '} found this helpful</p>
                        ) : ('')
                    }
                    <div class="flex items-center mt-3 space-x-3 divide-x divide-gray-200 dark:divide-gray-600">
                        <Button onClick={handleLike} class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-xs px-2 py-1.5 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" startIcon={!liked ? <ThumbUpOutlined />  : <ThumbUpAlt />}> Helpful</Button>
                        <Link to={`/report/${review._id}`} class="pl-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">Report abuse</Link>
                    </div>
                </aside>
            </CardContent>
        </Card>
        </>
    )
};

export const StationRating = ({reviews, star}) => {
    const ratings = [
        { stars: 5, percentage: 0 },
        { stars: 4, percentage: 0 },
        { stars: 3, percentage: 0 },
        { stars: 2, percentage: 0 },
        { stars: 1, percentage: 0 },
    ];
    // Calculate the total number of ratings
    const totalRatings = reviews.length;

    // Calculate the number of ratings for each star rating
    const starCounts = ratings.map((rating) => {
        const star = rating.stars;
        const count = reviews.filter((review) => review.rating === star).length;
        return { stars: star, count: count };
    });

    // Calculate the percentage for each rating
    starCounts.forEach((starCount) => {
        const percentage = (starCount.count / totalRatings) * 100;
        const ratingIndex = ratings.findIndex((rating) => rating.stars === starCount.stars);
        ratings[ratingIndex].percentage = percentage;
    });
    
    return(
        <div>
            {/* <div class="flex items-center">
                <p class="font-medium text-gray-900 dark:text-white">
                    <Rate value={star} readOnly />
                </p>
                <p class="ml-2 bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded dark:bg-blue-200 dark:text-blue-800">{star}</p>
                <span class="w-1 h-1 mx-2 bg-gray-900 rounded-full dark:bg-gray-500"></span>
                <p class="text-sm font-medium text-gray-500 dark:text-gray-400">{totalRatings} reviews</p>
                <a href="#" class="ml-auto text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">Read all reviews</a>
            </div> */}
            {ratings.map((rating) => (
                <Grid container spacing={2} style={{ alignItems: 'center', marginBottom: '8px' }} key={rating.stars}>
                <Grid item xs={9}>
                    <div style={{ width: '90%', height: '1rem', marginRight: '32px', backgroundColor: '#E0E0E0', borderRadius: '0.25rem', display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: '100%', backgroundColor: '#FFC107', borderRadius: '0.25rem', width: `${rating.percentage}%` }} />
                    </div>
                    {/* <Typography variant="body2" style={{ marginLeft: '0.5rem', color: '#757575' }}>
                        {rating.percentage}%
                    </Typography> */}
                </Grid>
                <Grid item xs={2}>
                    <small href="#" variant="body2" color="primary">
                        {rating.stars} star
                    </small>
                </Grid>
                </Grid>
            ))}
        </div>
    )
};

export const ReviewCardSkeleton = ({xs, sm, md}) => {
    return(
        Array.from({ length: 6 }).map((_, index) => (
            <Grid key={index} item xs={xs} sm={sm} md={md}>
              <Card className="mt-4" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
                <CardContent>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px', color: '#6B7280' }}>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Typography className="ml-5" style={{ fontSize: '1rem', color: '#374151', fontWeight: 'bold', cursor: 'pointer' }}>
                      <Link to="#" style={{ textDecoration: 'none', color: '#374151' }}>
                        <Skeleton variant="text" width={120} height={20} />
                      </Link> reviewed <Link to="#">
                        <Skeleton variant="text" width={100} height={20} />
                      </Link>
                    </Typography>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <Skeleton variant="text" width={40} height={20} />
                    </div>
                    <span className="text-secondary ml-2 mt-1">
                      <em style={{ fontSize: '12px' }}>
                        <Skeleton variant="text" width={40} height={20} />
                      </em>
                    </span>
                    <span className="text-secondary mt-1" style={{ fontSize: '0.875rem', fontWeight: 'normal', justifyContent: 'flex-end', marginLeft: 'auto' }}>
                      <Skeleton variant="text" width={80} height={20} />
                    </span>
                  </div>
                  <div style={{ fontSize: '1.25rem', color: '#374151', fontWeight: 'bold', cursor: 'pointer', transition: 'color 0.3s', ':hover': { textDecoration: 'underline' } }}>
                    <Skeleton variant="text" width={180} height={20} />
                  </div>
                  <Typography style={{ fontSize: '1rem', color: '#6B7280', marginTop: '16px' }}>
                    <Skeleton variant="text" width={'100%'} height={50} />
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'space-between' }}>
                  <div>
                    <Button size="small" color="primary">
                      <ThumbUpOutlined /> Like
                    </Button>
                  </div>
                  <Button size="small" color="secondary">
                    <Flag /> Report
                  </Button>
                </CardActions>
              </Card>
            </Grid>
        ))
    );
};