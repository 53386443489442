// components/ProtectedRoute.js

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state?.users).user;

  return isAuthenticated !== null ? children : <Navigate to="/login" />;
};

export const AdminRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state?.users).user;

  return isAuthenticated === 'admin' ? children : <Navigate to="/login" />;
};
