import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../Slice/UserSlice";
import AuthUserReducer from "../Slice/AuthUser";

const store = configureStore({
  reducer: {
    users: usersReducer,
    userAuth: AuthUserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable the ImmutableStateInvariantMiddleware
    }),
});

export default store;