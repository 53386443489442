import { Container, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { addStation } from "../../components/stations/StationsAPI";
import { getStates } from "../../components/states/StateApi";
import { useEffect, useState } from "react";
import MapComponent from "../../components/resources/Map";
import swal from 'sweetalert';

const AddStation = () => {
  const [state, setState] = useState([]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [formErrors, setFormErrors] = useState({
    name: '',
    address: '',
    lga: ''
  });

  const [selectedState, setSelectedState] = useState('all');
  const [selectedLGA, setSelectedLGA] = useState('all');

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const data = await getStates();
      setState(data);
    } catch (error) {
      console.error('Error fetching States:', error);
    }
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: '',
      address: '',
      lga: ''
    };

    // Validate station name
    if (name.trim() === '') {
      newErrors.name = 'Station name is required';
      valid = false;
    }

    // Validate station address
    if (address.trim() === '') {
      newErrors.address = 'Station address is required';
      valid = false;
    }

    // Validate LGA
    if (selectedLGA === '' || selectedLGA === 'Select State First' || selectedLGA === 'all') {
        newErrors.lga = 'Please select a valid LGA';
        valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        // Handle form submission
        const data = await addStation({ name, address, state: selectedState, lga: selectedLGA });
        if(data?.message === 'Station created'){
          swal({
            title: "Station Creted!",
            text: "Station has been submitted for approval",
            icon: "success",
            buttons: {
              link: {
                text: "Proceed",
                value: "link",
                className: "custom-link-button"
              },
            },
            closeOnClickOutside: false, // Prevent the modal from closing when the outside is clicked
            closeOnEsc: false          // Prevent the modal from closing when the escape key is pressed
          }).then((value) => {
            if (value === "link") {
              // Handle the "Visit Link" button click action here if needed
              // This block will run when the user clicks the "Visit Link" button
              // You can also add custom logic here if required.
              window.open(`/stations`, '_self');

            //   window.history.replaceState({}, document.title, '/');
            }
          });
        };
      } catch (error) {
        console.error('Error creating station:', error);
      }
    }
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedLGA('all');
  };

  const handleLGAChange = (event) => {
    setSelectedLGA(event.target.value);
  }

  const currentLGA = state.find((state) => state.name === selectedState);

  const renderLGASelect = () => {
    if (selectedState === '' || selectedState === "Select State" || selectedState === "all") {
      return (
        <Select
          labelId="lga-select-label"
          id="demo-simple-select"
          value='Select State First'
          label="Select LGA"
          disabled
        >
          <MenuItem value='Select State First'>Select State First</MenuItem>
        </Select>
      );
    } else {
      return (
        <Select
          labelId="lga-select-label"
          id="demo-simple-select"
          value={selectedLGA}
          label="Select LGA"
          onChange={handleLGAChange}
        >
          <MenuItem value="all">All</MenuItem>
          {currentLGA.lgas.map((item, i) => (
            <MenuItem key={i} value={item}>{item}</MenuItem>
          ))}
        </Select>
      );
    }
  };

  return (
    <div>
      <Container maxWidth="xl" sx={{ px: 4, py: 8 }}>
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit} className="mx-auto max-w-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
              Add Station
            </Typography>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Select State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select State"
                value={selectedState}
                onChange={handleStateChange}
                sx={{ borderColor: 'gray.200' }}
              >
                <MenuItem value="all">All</MenuItem>
                {state.map((state) => (
                  <MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="lga-select-label">Select LGA</InputLabel>
              {renderLGASelect()}
                <FormHelperText style={{ color: '#d32f2f' }}>{formErrors.lga}</FormHelperText>
            </FormControl>

            <TextField
                fullWidth
                variant="outlined"
                label="Station Name"
                placeholder="Station Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 2 }}
                error={formErrors.name !== ''}
                helperText={formErrors.name}
            />

            <TextField
                multiline
                fullWidth
                variant="outlined"
                size="small"
                label="Station Address"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                sx={{
                    marginBottom: '1rem',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                }}
                error={formErrors.address !== ''}
                helperText={formErrors.address}
            />

            <Typography variant="small" sx={{ mb: 2 }}>
              Station will be submitted for Approval.
            </Typography>

            <button
              type="submit"
              className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700"
            >
              Add Station
            </button>
          </form>
        </Container>
      </Container>
    </div>
  )
}

export default AddStation;
