import { useEffect, useState } from "react";
import { getStations } from "../../../components/stations/StationsAPI";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { getReviews } from "../../../components/reviews/ReviewAPI";
import PaginationTable from "../../../components/resources/PaginationTable";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";

const AllReviews = () => {
    
    const [review, setReviews] = useState([]);
    
    useEffect(() => {
        fetchReviews();
    }, []);
    
    const fetchReviews = async () => {
        try {
          const data = await getReviews();
          
            await setReviews(data.reviews);
          // console.log(sortedReviews);
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      };
    
    const sortedReviews = review
        .flatMap(station => station.reviews)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const updatedSortedReviews = sortedReviews.map(reviewitem => {
    const station = review.find(station => station.reviews.some(r => r._id === reviewitem._id));
        return {
            ...reviewitem,
            station_name: station.station_name,
            station_id: station.station_id
        };
    });

    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        {
            id: 'station_Name',
            label: 'Station Name',
            minWidth: 170,
            align: 'right',
          },
        {
          id: 'review',
          label: 'Review',
          minWidth: 170,
          align: 'right',
        },
        {
          id: 'rating',
          label: 'Rating',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
            id: 'price',
            label: 'Price',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
          id: 'likes',
          label: 'Likes',
          minWidth: 170,
          align: 'right',
          format: (value) => value,
        },
        {
            id: 'disLikes',
            label: 'Dislikes',
            minWidth: 170,
            align: 'right',
            format: (value) => value,
        },
        {
          id: 'reports',
          label: 'Reports',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
          id: 'created_at',
          label: 'Created At',
          minWidth: 170,
          align: 'right',
        },
    ];
    

    const createData = (name, station_Name, review, rating, price, likes, dislikes, reports, created_at) => {
        return { name, station_Name, review, rating, price, likes, dislikes, reports, created_at };
    }

    const rows = updatedSortedReviews.map((reviews) => (
        createData(reviews.username, reviews.station_name, reviews.comment, reviews.rating, reviews.price, reviews.likes.length, reviews.dislikes.length, reviews.size, reviews.created_at)
    ));

    console.log(updatedSortedReviews)
    return (
        <AdminLayout>
            <div style={{ justifyContent: 'space-between', display: 'flex'}}>
                <h2>All Reviews</h2>
            </div>
            <PaginationTable rows={rows} columns={columns} />
        </AdminLayout>
    )
}

export default AllReviews;