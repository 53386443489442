import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/Admin/layout/AdminLayout';
import { getReportedReviews, resolveReport } from '../../../components/reviews/ReportedReviewsApi'; // Import your API function
import PaginationTable from '../../../components/resources/PaginationTable'; // Import your PaginationTable component
import { Button } from '@mui/material';
import { ErrorModal } from '../../../components/resources/Modals';

const ReportedReviews = () => {
  const [reportedReviews, setReportedReviews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportToApprove, setReportToApprove] = useState(null);

  useEffect(() => {
    // Fetch reported reviews when the component mounts
    const fetchReportedReviews = async () => {
      try {
        const response = await getReportedReviews(); // Replace with your actual API function
        setReportedReviews(response);
      } catch (error) {
        console.error('Error fetching reported reviews:', error);
      }
    };

    fetchReportedReviews();
  }, []);
  
  const resolveReportedReview = async () => {
    try {
      console.log(reportToApprove);
      // Call your API function to resolve the reported review
      const response = await resolveReport(reportToApprove); // Replace with your actual API function
      
      setIsModalVisible(false);
  
      // Check if the response contains the resolved reported review object
      if (response && response._id) {
        // Handle success, you can update your UI or state here
        console.log('Reported review resolved successfully:', response);
  
        // You can also update your local state to indicate that the review is resolved if needed
        const updatedReportedReviews = reportedReviews.map((report) =>
          report._id === response._id ? { ...report, resolved: true } : report
        );
        setReportedReviews(updatedReportedReviews);
      } else {
        // Handle unexpected response format or any other error conditions
        console.error('Unexpected response when resolving reported review:', response);
      }
    } catch (error) {
      console.error('Error resolving review:', error);
      // Handle the error, you can display an error message or perform other actions
    }
  };
  

  const showApproveModal = (id) => {
    setReportToApprove(id);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { id: 'reviewId', label: 'Review ID', minWidth: 100 },
    { id: 'reporter', label: 'Reporter', minWidth: 170 },
    { id: 'reason', label: 'Reason', minWidth: 170 },
    { id: 'resolved', label: 'Status', minWidth: 100 },
    { id: 'action', label: 'Action', minWidth: 170 },
  ];

  const rows = reportedReviews.map((report) => ({
    id: report._id, // Assuming _id is a unique identifier for reported reviews
    reviewId: report.reviewId, // Replace with the actual field name
    reporter: report.reporter, // Replace with the actual field name
    reason: report.reason, // Replace with the actual field name
    resolved: report.resolved ? 'Resolved' : 'Pending', // Format the status
    action: (
      <>
          <Button onClick={(e) => {
              e.preventDefault();
              showApproveModal(report._id)
          }}>Resolve Issue</Button>
      </>
  ),
  }));
  const info = (
    <>
      <p>Are U Sure to resolve this Issue?</p>
    </>
  );
  
  return (
    <AdminLayout>
      <ErrorModal
          errorContent={info}
          isVisible={isModalVisible}
          onYes={resolveReportedReview}
          title={'Resolve Issue'}
          onNo={handleCancel}
      />
      <div style={{ justifyContent: 'space-between', display: 'flex'}}>
        <h2>Reported Reviews</h2>
      </div>
      <PaginationTable columns={columns} rows={rows} />
    </AdminLayout>
  );
};

export default ReportedReviews;
