import React, { useEffect, useState } from "react";
import { getSingleReview, updateReview } from "../../components/reviews/ReviewAPI";
import { Container, FormHelperText, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import RatingInput from "../../components/resources/RatingInput";
import { toast } from 'react-toastify';
import swal from 'sweetalert';

const EditReview = () => {
    
    
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [reviews, setReviews] = useState([]);
    const { station_id, reviewId } = useParams();
    const [price, setPrice] = useState();
    const [title, setTitle] = useState('');
    const [formErrors, setFormErrors] = useState({
        rating: '',
        email: '',
        price: '',
        comment: '',
        title: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSingleReview = async () => {
            try {
                const data = await getSingleReview(station_id, reviewId);
                setReviews(data.review);
                setRating(data.review.rating);
                setComment(data.review.comment);
                setPrice(data.review.price);
                setTitle(data.review.title);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchSingleReview(); // Call the fetchSingleReview function here
    }, [reviewId, station_id]);

    const validateForm = () => {
        let valid = true;
        const newErrors = {
          rating: '',
          price: '',
          comment: '',
          title: '',
        };
      
        // Validate Rating
        if (rating === 0) {
          newErrors.rating = 'Rating is required';
          valid = false;
        }
    
        // Validate Price
        if (price === '') {
          newErrors.price = 'Price is required';
          valid = false;
        }
      
        // Validate Comment
        if (comment.trim() === '') {
          newErrors.comment = 'Comment is required';
          valid = false;
        }
      
        // Validate Title
        if (title.trim() === '') {
          newErrors.title = 'Title is required';
          valid = false;
        }
      
        // Only validate email if it's visible (user is not logged in)
        // if (!localStorage.getItem('token')) {
        //   // Validate email format
        //   if (!emailPattern.test(email)) {
        //     newErrors.email = 'Incorrect email format';
        //     valid = false;
        //   }
        // }
      
        setFormErrors(newErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const data = await updateReview({station_id, reviewId, comment, rating, price, title})
                if(data?.message === 'Review updated successfully'){
                    swal({
                        title: "Review Edited!",
                        text: "Your review has been Edited",
                        icon: "success",
                        buttons: {
                          link: {
                            text: "Proceed",
                            value: "link",
                            className: "custom-link-button"
                          },
                        },
                        closeOnClickOutside: false, // Prevent the modal from closing when the outside is clicked
                        closeOnEsc: false          // Prevent the modal from closing when the escape key is pressed
                      }).then((value) => {
                        if (value === "link") {
                          // Handle the "Visit Link" button click action here if needed
                          // This block will run when the user clicks the "Visit Link" button
                          // You can also add custom logic here if required.
                          window.open(`/user/${reviews.username}`, '_self');
        
                        //   window.history.replaceState({}, document.title, '/');
                        }
                      });
                };
            } catch (error) {
                console.error('error updating')
                if (error?.response?.data?.message === 'not user') {
                    // navigate to home page with notification
                    navigate('/');
                    toast.error('You are not authorized to perform this action.', {
                        position: toast.POSITION.TOP_CENTER
                    });
    
                  }
            }
        };
    };

    const handleRatingChange = (value) => {
        setRating(value);
    };

    return (
        <div>
            <Container maxWidth="xl" sx={{ px: 4, py: 8 }}>
                <Container maxWidth="lg">
                    <form onSubmit={handleSubmit} className="mx-auto max-w-lg p-4 shadow-lg sm:p-6 lg:p-8">
                    <Typography variant="h6" sx={{ mb: 2 }}>
                    Edit Your Review
                    </Typography>
                    <Typography variant="p" align="center" sx={{ mb: 2 }}>
                    Submitting your review takes just a minute and can provide valuable insights for fellow users seeking information about filling stations. <br />
                    </Typography>
                    
                    {/* Conditional rendering of Select component based on selected state */}
                    <RatingInput
                    allowHalf
                    size="large"
                    value={rating} // Set the selected rating value
                    onChange={handleRatingChange} // Handle rating change
                    />
                    <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.rating}</FormHelperText>

                    {/* Price */}
                    <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    label="Price"
                    placeholder="Enter the price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    sx={{
                        mb: 2,
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        appearance: 'none',
                        margin: 0,
                        },
                    }}
                    error={formErrors.price !== ''}
                    helperText={formErrors.price}
                    />

                    {/* Comment */}
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="outlined-textarea"
                        label="Tell us about Your Experience"
                        placeholder="Share your experience, including what you liked, disliked, and any helpful insights for others."
                        multiline
                        rows={8}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        sx={{
                            marginBottom: '1rem', // Adjust margin as needed
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)', // Equivalent to shadow-sm
                        }}
                        error={formErrors.comment !== ''}
                        helperText={formErrors.comment}
                    />

                    {/* Title */}
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Title"
                        placeholder="Review Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{
                            mb: 2,
                        }}
                        error={formErrors.title !== ''}
                        helperText={formErrors.title}
                        />

                    <button
                        type="submit"
                        className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700"
                    >
                        Submit Review
                    </button>
                    </form>
                </Container>
            </Container>
        </div>
    )
}

export default EditReview;
